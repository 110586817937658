<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="/img/logo-dialog.405b5032.svg" alt="" />
    </b-link>
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form
            class="offset-md-3 col-md-6 text-center auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  class="text-center"
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="example@domain.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    class="form-control-merge text-center"
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    name="login-password"
                    :placeholder="$t('Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
           <div class="d-flex justify-content-center">
             <b-button type="submit" class="btn-login"> {{$t("Login")}} </b-button>
           </div>
          </b-form>
        </validation-observer>
        
        <b-card-text 
        v-if="allowToRegister"
        class="register-login-btn no-border text-center mt-2">
          <b-link :to="{name:'auth-register', query: { token: $route.query.token}}">
            <span>{{$t("Register")}}</span>
          </b-link>
        </b-card-text>
        <b-card-text class="forgot-btn text-center mt-2">
          <b-link :to="{ name: 'auth-forgot-password' }">
            <span>{{$t("Forgot Password?")}}</span>
          </b-link>
          <p></p>
          <b-link :to="{ name: 'resend-activation' }">
            <span>{{$t("Resend Activation")}}</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
    <a class="mercatus-logo" href="https://cooling.mercatus.pt/pt/home" target="https://cooling.mercatus.pt/pt/home">
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt="" />
    </a>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CostLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    CostLogo,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      allowToRegister: false,
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              useJwt.setToken(response.data.access);
              useJwt.setRefreshToken(response.data.refresh);
              return useJwt.getUser();
            })
            .then((response) => {
              const userData = {
                id: response.data.id,
                fullName: response.data.first_name,
                username: response.data.email,
                // eslint-disable-next-line global-require
                avatar: "",
                email: response.data.email,
                role: "admin",
                ability: [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ],
                extras: {
                  eCommerceCartItemsCount: 5,
                },
              };
              localStorage.setItem("userData", JSON.stringify(userData));
              this.$ability.update(userData.ability);
              if(this.$route.query.token)
              {
                this.redirect_to_sync(userData.role)
              }
              else
              {
                this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                    this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `${this.$t("Welcome")} ${
                        userData.fullName
                      }`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  });
                  }
                )
              };
            })
            .catch((error) => {
              let msg_error = error.response.data.detail
              if(this.$route.query.token)
              {
                msg_error = this.$t("Please validate your registration, go to your email account and click on the link we will send")
              }
              this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Login Invalid',
                    icon: 'AlertTriangleIcon',
                    text: msg_error
                  }})
              this.$refs.loginForm.setErrors(error.response.data.error);
            });
        }
      });
    },
    redirect_to_sync(role)
    {
      let devicesEndpoint = `${process.env.VUE_APP_API_URL}api/devices/`
      let data = {token:this.$route.query.token}
      this.$http.post(`${devicesEndpoint}sync-device/`, data)
      .then(success => {
        if(success.status === 200)
        {
           this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sync Device',
                  icon: 'RefreshCwIcon',
                  variant: 'success',
                  text: success.data.message
                },
              }) 
        }
        this.$router.replace(getHomeRouteForLoggedInUser(role))
      }
      )
      .catch(error =>{
      this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Token Invalid',
                    icon: 'AlertTriangleIcon',
                    text: error.data
                  },
                })})    
    }
  },
  created(){
    if (this.$route.query.token)
    {
      let devicesEndpoint = `${process.env.VUE_APP_API_URL}api/devices/`
      let data = {token:this.$route.query.token}
      this.$http.post(`${devicesEndpoint}validate-token/`, data)
      .then(success => {
        if(success.status === 200)
        {
          this.allowToRegister = true
        }
      }
      )
      .catch(error =>{
      this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Token Invalid',
                    icon: 'AlertTriangleIcon',
                    text: error.data
                  },
                })})
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
